import * as React from 'react';
import { graphql } from 'gatsby';
import PostCardModern from '../components/post-card-modern/post-card-modern';
import Pagination from '../components/pagination/pagination';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BlogPostsWrapper, PostRow, PostGrid } from './templates.style';
import { compose } from 'styled-system';

const BlogList = (props: any) => {
  const { data } = props;
  const Posts = data.allStrapiArticles.edges;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '/stranka/1' : `/stranka/${(currentPage - 1).toString()}`;
  const nextPage = `/stranka/${(currentPage + 1).toString()}`;
  const PrevLink = !isFirst && prevPage;
  const NextLink = !isLast && nextPage;

  //console.log(Posts);

  return (
    <Layout>
      <SEO title={`Page ${currentPage}`} />

      <BlogPostsWrapper>
        <PostRow>
          {Posts.map(({ node }: any) => {
            // Random Placeholder Color
            const placeholderColors = [
              '#55efc4',
              '#81ecec',
              '#74b9ff',
              '#a29bfe',
              '#ffeaa7',
              '#fab1a0',
              '#e17055',
              '#0984e3',
              '#badc58',
              '#c7ecee',
            ];
            const setColor =
              placeholderColors[
                Math.floor(Math.random() * placeholderColors.length)
              ]; 

             

            return (
              <PostGrid>
                <PostCardModern
                  key={node.id}
                  title={node.title}
                  image={
                    node.cover == null ? null : node.cover.childImageSharp.fluid
                  }
                  url={node.slug}
                  description={node.excerpt}
                  date="3 <span> cerv </span>"
                  tags={[node.tag]}
                  placeholderBG={setColor}
                />
              </PostGrid>
            );

          
          })}


        </PostRow>

        <Pagination
          prevLink={PrevLink}
          nextLink={NextLink}
          currentPage={`${currentPage}`}
          totalPage={`${numPages}`}
        />
      </BlogPostsWrapper>
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    sitePage {
      path
      
    }

    allStrapiArticles(filter: {public: {eq: true}}, sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip) {
      edges {
        node {
          title
          slug
          id
          createdAt
          tag
          excerpt
          public

          cover {
            childImageSharp {
              fluid(maxWidth: 1170, maxHeight: 690, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

/*
    allStrapiBlogs {
      edges {
        node {
          excerpt
          datum
          description
          tags
          slug
          cover {
            childImageSharp {
              fluid(maxWidth: 1170, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    */

/*export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    sitePage {
      path
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD [<span>] MMMM [</span>]")
            title
            description
            tags
            cover {
              childImageSharp {
                fluid(maxWidth: 1170, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;

*/

/*
<PostRow>
{Posts.map(({ node }: any) => {
  // Random Placeholder Color
  const placeholderColors = [
    '#55efc4',
    '#81ecec',
    '#74b9ff',
    '#a29bfe',
    '#ffeaa7',
    '#fab1a0',
    '#e17055',
    '#0984e3',
    '#badc58',
    '#c7ecee',
  ];
  const setColor =
    placeholderColors[
      Math.floor(Math.random() * placeholderColors.length)
    ];
  return (
    <PostGrid>
      <PostCardModern
        key={node.fields.slug}
        title={node.frontmatter.title || node.fields.slug}
        image={
          node.frontmatter.cover == null
            ? null
            : node.frontmatter.cover.childImageSharp.fluid
        }
        url={node.fields.slug}
        description={node.frontmatter.description || node.excerpt}
        date={node.frontmatter.date}
        tags={node.frontmatter.tags}
        placeholderBG={setColor}
      />
    </PostGrid>
  );
})}
</PostRow> */
